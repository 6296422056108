/* eslint-disable import/no-anonymous-default-export */
export default {
  Header: {
    home: 'Home',
    about: 'About Us',
    solution: 'Solutions',
    soho: 'SOHO',
    partnership: 'Partnerships',
    contact: 'Contact',
    error: "Error (404) - Page not found!",
    language: 'Choose Language',
    title: 'You Can Save Money And Deploys A Great Solution!',
    description:
      'Come to the future and knows that is possible to implement a safe and easy solution at your budget expectations. OCTOSYSTEMS offers a less wire connections and a auto sync configuration. Does not matter the size of your company we can support.',
  },
  Offer: {
    heading1: 'We Offer Access Control Solution',
    heading2: 'Under A Minimum Infrastructure !',
    description:
      'No worries to install a access control because OctoSystems can provide you a easiest way to do it with a affordable, reliable and robust devices.',
    card1: 'IOT',
    card2: 'Development',
    card3: 'Access Control',
    card4: 'White Label',
  },
  CompanyDetails: {
    heading: 'Your Company Brand is Welcome For a WHITE LABEL Solution.',
    description: 'We provide a full pack of solution customized for you.',
    headingOne: '01',
    headingTwo: '02',
    bodyOne:
      "We know that a big amount of money are expended in the installing process even in the new or already exists cases. We think our solution to exclude this issue from your budget. Don't waist time, dont waist money, just invest in the best cost benefits.",
    bodyTwo:
      'Our devices generation are developed to be easy to install, the new devices are discovered in seconds and can be configured with a maximum security without any difficulty. No matter its a new project or a legacy one.',
    status1: 'Successful projects',
    status2: 'Happy Customers',
    status3: 'Customer Satisfaction',
  },
  Demo: {
    heading:
      'We believe be possible to offer a high level solution for all company sizes. Easy to install and operate at affordable investment !',
    button: 'Request a Demo',
  },
  SatisfactionDetails: {
    heading: 'Customer Satisfaction Guaranteed',
    title1: 'Vanessa Willians',
    title2: 'Miler Eduard Smith',
    title3: 'Roger Van Dennyson',
    description1:
      'I work for a security company and I chose OCTO as a partner for a better offer to my clients. Head Engineer at TOP security',
    description2:
      'I discovered that with OCTO I can have a system like those big corporations in a simple and accessible way',
    description3:
      'My integrator installed the same system in my office and at home, it was fast and very functional.',
    designation1: '- Manager at VR Studio',
    designation2: '- CEO at Epi Technologies',
    designation3: '- Manager at Rentals Systems',
  },
  Footer: {
    heading:
      'OCTOSYSTEMS - Easy, Reliable and Robust Access Control Solution + IOT controls in a same platform',
    linkText: 'Quick Links',
    home: 'Home',
    about: 'About Us',
    solution: 'Solutions',
    soho: 'SOHO',
    partnership: 'Partnerships',
    contact: 'Contact',
    copyright: 'Copyright',
    rights: 'octosystems. All Rights Reserved.',
  },
  About: {
    header_heading: 'Over 30 years experienced team !',
    header_content:
      'OCTOSYSTEMS is a company that unify the professionals from security, access control and IOT with several years of experience.',
    body_heading: 'ACCESS CONTROL',
    body_content: 'EASY, RELIABLE AND ROBUST',
    card1heading: 'PROJECTS DEVELOPMENT IS OUR DNA',
    card1content:
      'More than simple projects ! We believe that constant development and customization for the several markets is our daily mission.',
    card2heading: 'WHITE LABEL SOLUTIONS',
    card2content:
      'We loves stay creating and our ambition is to invite you for a opportunity to growth together. You can have all OCTOSYSTEMS devices and software with your BRAND and customizations.',
    card3heading: 'WE ARE IN SOUTH AMERICA AND EUROPE',
    card3content:
      'Our team is prepared to give you the best services and solutions in different areas. Branch offices in Brazil and Portugal.',
    card4heading: 'CONNECTING THE WORLD OF SOLUTIONS',
    card4content:
      'We are open to talk to all other devices and softwares as needed. You are welcome to integrate your solution with us. Please contact OCTOSYSTEMS team.',
  },
  Solutions: {
    heading: `Join OCTOSYSTEMS' world and change the way you use access control systems to better !`,
    TechnologyHeading: 'Technology Of The Future',
    TechnologySection1Icon: '01',
    TechnologySection1Title: 'SOFTWARE SOLUTION PLATFORM',
    TechnologySection1Body:
      'You can use SaaS platform for access control and IOT management or choose our appliance device for on premise deployment.',
    TechnologySection2Icon: '02',
    TechnologySection2Title: 'SCALABLE PROJECTS',
    TechnologySection2Body:
      'No matter the size of projects from SOHO to huge corporations OCTOSYSTEMS can offer several combinations of devices in order to fits all need.',
    TechnologySection3Icon: '03',
    TechnologySection3Title: 'OCTO COMM NET',
    TechnologySection3Body:
      'All devices uses OCTO COMM communication protocoal to ensure your project a maximum security and easy way to connecting.',
    BenefitsHeading:
      'Additional benefits you get when working with OCTOSYSTEMS',
    headingBox1: 'PROTOCOLS',
    item1Box1: 'Wiegand',
    item2Box1: 'TCP/IP',
    item3Box1: 'RF & MIFARE',
    item4Box1: 'MQTT',
    item5Box1: 'SHA 128',
    item6Box1: 'HTTPS',
    headingBox2: 'DEVICES',
    item1Box2: 'CARD READ/WRITE',
    item2Box2: 'QR CODE READER',
    item3Box2: 'I/O DIGITAL AND ANALOGIC',
    item4Box2: 'SENSORS',
    headingBox3: 'CONTROLLER',
    item1Box3: 'MASTER CONTROLLER',
    item2Box3: 'EMBEDDED SERVER',
    item3Box3: 'WEB PLATFORM',
    item4Box3: 'RULES GENERATOR',
    headingBox4: 'INTEGRATORS',
    item1Box4: 'API COMM',
    item2Box4: 'HARDWARE GATEWAY',
    item3Box4: 'IOT CONTROL',
    // ---- Details Section ------ //
    detailsHeading1: 'Bridge from past to the future !',
    detailsDescription1:
      'We do not want to hear you are arrested by some legacy and are not able to a new implementation without waste all old devices. We offer integration with several solutions in order to give you opportunity to just move step by step from that to the new generation OCTOSYSTEMS.',
    detailsHeading2: 'Simplicity Is Key',
    detailsDescription2:
      'We developed a solution pack thinking in combination of time reduction in the physical installing together a low time configuration and easy operating process. All them together can be save money to sellers also buyers. The result is the most desired in any project: MONEY SAVE.',
    // ------ Technical Feature Section --------- //
    featuresHeading: 'Technical features',
    featuresTitle1: 'EASY API INTEGRATION',
    featuresTitle2: 'INTUITIVE CONFIGURATION',
    featuresTitle3: 'SCRIPTS CAPABILITY',
    featuresTitle4: 'SOHO & CORPORATE',
    featuresTitle5: 'MOBILE & RESPONSIVE',
    featuresTitle6: 'MINIMUM WIRE CONNECTION',
    featuresTitle7: 'SAAS & ON-PREMISE',
    featuresTitle8: 'MULTI LANGUAGE',
    featuresTitle9: 'IOT TECHNOLOGY',
    featuresTitle10: 'ACCEPTS LEGACY',
    featuresTitle11: 'AFFORDABLE SOLUTION',
    featuresTitle12: 'AUTO UPDATABLE',

    // ---------------Models and components Section -----------

    modelsHeading: 'Models and Components',
    modelsSubHeading: 'A technology device playground to your business',

    modelBox1: 'OCTO CONTROL',
    MnC_item1Box1: 'Access control and IOT management platform',
    MnC_item2Box1: 'SaaS Web or On Premise Appliance',
    MnC_item3Box1: 'Multi tenant',
    MnC_item4Box1: '50.000 users and 5.000 devices',
    MnC_item5Box1: 'Multi language',
    MnC_item6Box1: 'Intuitive',

    modelBox2: 'OCTO GATEWAY',
    MnC_item1Box2: 'Gateway module for until 50 devices',
    MnC_item2Box2: 'Mini UPS inside',
    MnC_item3Box2: 'Several protocols',
    MnC_item4Box2: 'Secure OCTO COMM mesh control',
    item5Box2: 'Management of health status devices',
    item6Box2: 'Fast response',
    item7Box2: 'Mini module size for convenient installation',
    item8Box2: 'Ethernet and WiFi simultaneous',

    modelBox3: 'OCTO APPLIANCE',
    MnC_item1Box3: 'Stand alone access control and IOT system',
    MnC_item2Box3: 'Sync to SaaS and works redundant',
    MnC_item3Box3: 'Web access',
    MnC_item4Box3: 'Integrates with other 3rd part systems',

    modelBox4: 'OCTO COMM READER',
    MnC_item1Box4: 'QR Code',
    MnC_item2Box4: 'MIFARE',
    MnC_item3Box4: '13.56 MHZ / 125KHZ / 433 MHZ',
    item4Box4: 'UHF',
    item5Box4: 'Wirelles data transmition',
    item6Box4: 'Octo COMM secure protocol',

    modelBox5: 'OCTO COMM I/O',
    MnC_item1Box5: 'Up to 8 Relays',
    MnC_item2Box5: 'Up to 8 Inputs',
    MnC_item3Box5: 'Able for Button uses',
    MnC_item4Box5: 'Able for Alarm reports & aquicsitions',
    MnC_item5Box5: 'Wirelles data transmition',
    MnC_item6Box5: 'Octo COMM secure protocol',

    modelBox6: 'OCTO COMM SENSOR',
    MnC_item1Box6: 'Temperature',
    MnC_item2Box6: 'Humidity',
    MnC_item3Box6: 'Energy meter',
    MnC_item4Box6: 'Moviment detection',
    MnC_item5Box6: 'Sound detection',
    MnC_item6Box6: 'Wirelles data transmition',
    MnC_item7Box6: 'Octo COMM secure protocol',
  },
  Partnership: {
    alerterHeading:`Let's TALK ?`,
    alerterContent:'PLEASE VISIT OUR CONTACT PAGE FORM FOR PARTNERSHIP OR DIRECTIONS',
    alerterContact:'Contact now',
    hh1: 'YOUR COMPANY IS',
    hh2: 'Welcome',
    hh3: 'for a PARTNERSHIP',
    heading: 'Come together',
    subheading: 'Your company can be choose some ways to be with us !',
    card1_heading: 'Your own solution pack',
    card1_content:
      'We can supply the complete solution of hardware and software with your own brand.',
    card2_heading: 'Control devices',
    card2_content:
      'We are open to talk with your devices, no matter your brand we would like to be at your side.',
    card3_heading: 'Developers',
    card3_content:
      'OUTSYSTEMS is proud to have good relationship with a pool of developers, if you have a project to improve the solution you are welcome.',
    card4_heading: 'Your software',
    card4_content:
      'You are invited to have your software solution talking with our OCTOSYSTEMS',
    card5_heading: 'Resellers',
    card5_content:
      'Integrators can represent OCTOSYSTEMS in our area and be more competitve in technology and prices.',
    card6_heading: 'Services',
    card6_content: `Your company can have our team as yours. Let's talk about.`,
    detail1_heading: 'We provide training',
    detail1_content:
      'We know the importance of this matter and we would like to invest in your acknowledgement sure that we all grow together',
    detail2_heading: 'Technical support',
    detail2_content:
      'Our specialists are available in Omni Channels to provide the best conditions for your projects.',
    detail3_heading: 'We talks your language',
    detail3_content:
      'We can offer training, technical support and manuals in Portuguese, Spanish and English.',
  },
  SOHO:{
    header_heading:'OCTO SOHO',
    header_content_1:'A Small Module but a',
    header_content_2:'Powerful and BIG Smart Access Controller...',
    portfolio_small_heading:'AGGREGATE VALUE TO YOUR PORTFOLIO.',
    portfolio_heading:'Octosystems SOHO',
    portfolio_content:'OctoSOHO is designed to your best solution offer as White Label. Simple as a kit involving a cloud management where you can have as a SaaS to your customers',
    portfolio_card1:'WIFI & 4G ENABLED',
    portfolio_card2:'MULTI LANGUAGE',
    portfolio_card3:'MULTI USERS',
    portfolio_card4:'API FOR INTEGRATIONS',
    portfolio_card5:'CLOUD & MOBILE',
    portfolio_card6:'AUTOMATIC UPDATE',
    portfolio_card7:'SECURE CONNECTIONS',
    portfolio_card8:'BUILT IN BATT & CLOCK',
    access_control_h1:'Access Control As You Need',
    access_control_h2:'Access Control, Easy, Secure, Robust',
    access_control_card1_heading:'Controls',
    access_control_card1_content:'Octo SOHO can control any kind of device via remote relays or wireless. APIs can provide many other ways to integrate your appliances, smart locks, engines, doors, gates. SOHO is the unique access control solution with 4g ready for use. You can setup in few seconds just scanning a QR code and sync with your account. A great choose for several points in your house, offices and other locals.',
    access_control_card2_heading:'Connections',
    access_control_card2_content:'Despite you can use OCTO SOHO via WiFi and/or 4g there is also possible to phisical connections, some special versions can supply WIEGAND, CONTACT relay and ETHERNET as needed. You can request OEM/WHITE LABEL Pack with the best fit for your projects and consumers. If you have some specific devices that wants do manage by our solution can talk to it via several modes of communication. Just contact our team and we can discuss about your project. The remote wireless relay is standard and comes with OCTO SOHO PACK.',
    access_control_card3_heading:'Management',
    access_control_card3_content:'The OCTO CONTROL WEB manage all users, groups, places and devices for you. You can easily receives notifications in your mobile phone over the platform for any change in the status. The simplicity way to operate the platform gives you a powerful way to manage access as a big corporative system but maintaining the cost benefits.',
    access_controller_heading:'ACCESS CONTROLLER',
    access_controller_content:`OCTO SOHO is a high performance and robust device for a quick and easy deployment for all level users and applications. You can just scan a QR code using your mobile phone and automatically register the device in the OCTOCONTROL platform. OCTO SOHO comes from manufacture with a 4G communication already enabled *** and it's allow you to install in places were you don't have any WIFI network and giving you the unlimited applies. The device has options for several modes connections and can be able to receive signals from RFID cards, QR codes, mobile phone bluetooth or NFC and can control any other device via relays, WIFI, API and any other protocols. No matter the place you are in the world OCTO is for you. Your houses, office, garages, stores, gates, wharehouses and even farms can be supported by OCTO due the 4G comunication as no other competitor has, you can choose BLACK, LIGHT GREY or WHITE color to combine with the place will be installed.`,
    control_heading:'OCTO CONTROL WEB',
    control_content:'All access control details, configs, logs can be managed by our web SaaS platform. You can have access via your mobile phone, notebook, desktop or even via tablets, the platform is responsive and easy to use. Forget about any difficult to operate a access control system, with OCTO solutions you are able to use a high level access control security system as big corporations for a affordable investment. The platform can management all your places, users and devices as group for your confort. You can have a complete control of all devices and theirs status in only one place.',
    featured_table_r1c1:'OCTO Models',
    featured_table_r1c2:'SOHO STD',
    featured_table_r1c3:'SOHO PLUS',
    featured_table_r1c4:'SOHO ADV',
    featured_table_r2c1:'BLUETOOTH',
    featured_table_r3c1:'MIFAIR / NFC',
    featured_table_r4c1:'QR CODE',
    featured_table_r5c1:'1 REMOTE RELAY',
    pricing_table_r1c1:'OCTO CONTROL WEB MANAGEMENT',
    pricing_table_r1c2:'12 Months Free **',
    pricing_table_r2c1:'Users',
    pricing_table_r3c1:'Octo SOHO devices',
    pricing_table_r4c1:'Groups',
    pricing_table_upto:'up to 5',
    pricing_table_r5c1:'Logs',
    pricing_table_max:'max 500',
    pricing_table_description:'** Additional resources and features under subscription.',
    approval_heading:'Approved by household and office users !',
    approval_content:'The easy mode operation advantages brings this solution as fitted option for all kinds of installations, houses, restaurants, offices, storages, shared places...',
    customize_solution_heading:'For integrators and security companies:',
    customize_solution_content:'Customized access control solution for small office and residences to put you on the top of competitors. You can deploy in minutes a system with features as only the biggest players can offer for a affordable price.',
    customize_solution_logo_alt:'Request your White Label Solution',
    setup_top:'Intuitive and fast setup !',
    setup_wifi1:'WI',
    setup_wifi2:'FI',
    setup_cloud:'CLOUD',
    setup_4g1:'4',
    setup_4g2:'G',
    detailed_portfolio_small_heading:'AGGREGATE VALUE TO YOUR PORTFOLIO.',
    detailed_portfolio_heading:'Octosystems SOHO',
    detailed_portfolio_content:'OctoSOHO is designed to your best solution offer as White Label. Simple as a kit involving a cloud management where you can have as a SaaS to your customers',
    detailed_portfolio_card1_heading:'Auto update',
    detailed_portfolio_card1_content:'New firmwares are updated via the cloud platform automatically',
    detailed_portfolio_card2_heading:'The platform is responsive',
    detailed_portfolio_card2_content:'No matter you use your PC or Mobile phone the plataform can support it.',
    detailed_portfolio_card3_heading:'Multi Language',
    detailed_portfolio_card3_content:'Your company can offers worldwide the solution',
    detailed_portfolio_card4_heading:'Multi Tenant',
    detailed_portfolio_card4_content:'You can manage several customers and them can manage multiple users',
    detailed_portfolio_card5_heading:'Battery Inside',
    detailed_portfolio_card5_content:`Don't worry about some energy cuts, the system has internal battery`,
    detailed_portfolio_card6_heading:'Service based Mode',
    detailed_portfolio_card6_content:'Offer as a SaaS the complete solution',
    detailed_portfolio_line1:'Minimum infrastructure;',
    detailed_portfolio_line2:`Easy setup and software configuration, P'N'P;`,
    detailed_portfolio_line3:'Just plug to energy and scan QR code;',
    detailed_portfolio_line4:'Several kind of applications, serveral king of devices controlled;',
    detailed_portfolio_line5:'The ONLY in the market with 4G pre enabled from factory;',
    about_small_heading:'RISE YOUR EFFICIENCY',
    about_heading:'The Best World Team working for your company!',
    about_line1:'Our team has more than 30 years experience in technology.',
    about_line2:'Our DNA is to create solutions that fits to the partners portfolios and their customers. Security and IOT are working together in our systems to give you the best solution.',
    about_line3:'You are invited to know better about this new generation access control solution.',
    about_line4:'Contact us and be our partner',
  },
  Contact:{
    heading:`Got a project? Let's talk`,
    line1:'No matter the size of your project we are open to receive your message.',
    line2:'Please tell us about a bit about your project and we are pleasure to exchange experiences for a cooperation.',
    form_fname:'First name*',
    form_lname:'Last name*',
    form_title:'Title*',
    form_company:'Company*',
    form_email:'Work email*',
    form_submit:`Submit`,
    form_error:'This feild is required.',
    form_footer_t1:`By clicking submit you agree to ai lab's `,
    form_footer_t2:`privacy policy.`,
    address_detail_h1:'We welcome you to talk in person at our addresses',
    address_detail_p1:`Octo Systems was designed to be flexible and able to adjust and fits to all markets and can be customized to your company as final customer or as a security service provider. Come to talk about your projects.`,
    address_detail_EUH:'EUROPE OFFICE',
    address_detail_EUP:'Arcos de Valdevez_Portugal',
    address_detail_BRH:'BRAZIL OFFICE',
    address_detail_BRP:'São Paulo _ Brazil',
    address_detail_email_t:'Email Address',
    address_detail_email:'adm@octosystems.com.br'
  },
  Error_Page:{
    hh1:"Page you requested if not found",
    hh2:"Error :  404",
    hh3:"Please check url",
  }
};
