const translation = {
  Header: {
    home: 'Home',
    about: 'Sobre Nós',
    solution: 'Soluções',
    soho: 'SOHO',
    partnership: 'Parceria',
    contact: 'Contato',
    error: "Erro (404) - Página não encontrada!",
    language: 'Escolha o seu idioma',
    title: 'Faça a melhor opção de controle de acessos com o melhor custo benefício !',
    description:
      'Venha para o futuro e saiba que é possível implementar uma solução segura e fácil dentro das suas expectativas de orçamento. A OCTOSYSTEMS oferece menos conexões de fios e uma configuração de sincronização automática. Não importa o tamanho da sua empresa, nós podemos oferecer suporte.',
  },
  Offer: {
    heading1: 'Oferecemos solução de controle de acessos ',
    heading2: 'com a mínima infraestrutura !',
    description:
      'Implantar um controle de acessos agora é mais simples porque a OctoSystems pode oferecer a você uma maneira mais fácil de fazê-lo com dispositivos acessíveis, confiáveis e robustos.',
    card1: 'IOT',
    card2: 'Desenvolvimento',
    card3: 'Controle de acesso',
    card4: 'White Label',
  },
  CompanyDetails: {
    heading:
      'Sua marca é bem-vinda para uma solução de white label.',
    description:
      'Nós fornecemos um pacote completo de solução personalizada para você.',
    headingOne: '01',
    headingTwo: '02',
    bodyOne:
      'Sabemos que uma grande quantidade de dinheiro é gasta no processo de instalação, mesmo em casos novos ou existentes. Acreditamos que nossa solução exclui esse problema do seu orçamento. Não perca tempo, não perca dinheiro, simplesmente invista no melhor custo-benefício.',
    bodyTwo:
      'Nossa geração de dispositivos foi desenvolvida para ser fácil de instalar, os novos dispositivos são descobertos em segundos e podem ser configurados com máxima segurança sem dificuldades. Não importa se é um projeto novo ou legado.',
    status1: 'Projetos de sucesso',
    status2: 'Clientes satisfeitos',
    status3: 'Satisfação do cliente',
  },
  Demo: {
    heading:
      'Acreditamos ser possível oferecer uma solução de alto nível para empresas de todos os tamanhos. Fácil de instalar e operar, com um investimento acessível!',
    button: 'Solicitar uma demonstração',
  },
  SatisfactionDetails: {
    heading: 'Satisfação do cliente garantida',
    title1: 'Vanessa Willians',
    title2: 'Miler Eduard Smith',
    title3: 'Roger Van Dennyson',
    description1:
      'Eu trabalho para uma empresa de segurança e escolhi a OCTO como parceira para oferecer uma melhor oferta aos meus clientes. Sou o Engenheira-Chefe da TOP Security.',
    description2:
      'Descobri que com a OCTO posso ter um sistema semelhante ao das grandes corporações de forma simples e acessível.',
    description3:
      'Meu integrador instalou o mesmo sistema no meu escritório e em minha casa, foi rápido e muito funcional.',
    designation1: '',
    designation2: '- CEO na Epi Technologies.',
    designation3: '- Manager at Rentals Systems',
  },
  Footer: {
    heading:
      'OCTOSYSTEMS - Solução de Controle de Acessos Fácil, Confiável e Robusta + Controles de IoT em uma mesma plataforma',
    linkText: 'Links rápidos',
    home: 'Home',
    about: 'Sobre Nós',
    solution: 'Soluções',
    soho: 'SOHO',
    partnership: 'Parceria',
    contact: 'Contato',
    copyright: 'Direitos autorais',
    rights: 'OctoSystems. Todos os direitos reservados.',
  },
  About: {
    header_heading: 'Equipe com mais de 30 anos de experiência!',
    header_content:
      'A OCTOSYSTEMS é uma empresa que unifica os profissionais de segurança, controle de acesso e IOT com vários anos de experiência.',
    body_heading: 'CONTROLE DE ACESSO',
    body_content: 'FÁCIL, CONFIÁVEL E ROBUSTO',
    card1heading: 'DESENVOLVER PROJETOS É O NOSSO DNA',
    card1content:
      'Mais do que simples projetos! Acreditamos que o constante desenvolvimento e customização para os diversos mercados é a nossa missão diária.',
    card2heading: 'SOLUÇÕES DE ETIQUETA BRANCA',
    card2content:
      'Adoramos continuar criando e nossa ambição é convidar você para uma oportunidade de crescimento juntos. Você pode ter todos os dispositivos e software OCTOSYSTEMS com sua MARCA e personalizações.',
    card3heading: 'ESTAMOS NA AMÉRICA DO SUL E EUROPA',
    card3content:
      'Nossa equipe está preparada para lhe oferecer os melhores serviços e soluções em diversas áreas. Filiais no Brasil e em Portugal.',
    card4heading: 'CONECTANDO O MUNDO DAS SOLUÇÕES',
    card4content:
      'Estamos abertos para conversar com todos os outros dispositivos e softwares conforme necessário. Você está convidado a integrar sua solução conosco. Entre em contato com a equipe da OCTOSYSTEMS.',
  },
  Solutions: {
    heading:
      'Junte-se ao mundo da OCTOSYSTEMS e mude a maneira como você usa os sistemas de controle de acessos para melhor!',
    TechnologyHeading: 'Tecnologia do futuro',
    TechnologySection1Icon: '01',
    TechnologySection1Title: 'PLATAFORMA DE SOLUÇÕES DE SOFTWARE',
    TechnologySection1Body:
      'Você pode usar a plataforma SaaS para controle de acessos e gerenciamento de IoT ou escolher nosso dispositivo para implantação local.',
    TechnologySection2Icon: '02',
    TechnologySection2Title: 'PROJETOS ESCALÁVEIS',
    TechnologySection2Body:
      'Não importa o tamanho dos projetos, desde pequenos escritórios até grandes corporações, a OCTOSYSTEMS pode oferecer várias combinações de dispositivos para atender a todas as necessidades.',
    TechnologySection3Icon: '03',
    TechnologySection3Title: 'OCTO COMM NET',
    TechnologySection3Body:
      'Todos os dispositivos utilizam o protocolo de comunicação em malha OCTO COMM para garantir a máxima segurança do seu projeto e uma maneira fácil de se conectar.',
    BenefitsHeading:
      'Benefícios adicionais que você obtém ao trabalhar com a OCTOSYSTEMS',
    headingBox1: 'PROTOCOLOS',
    item1Box1: 'Wiegand',
    item2Box1: 'TCP/IP',
    item3Box1: 'RF & MIFARE',
    item4Box1: 'MQTT',
    item5Box1: 'SHA 128',
    item6Box1: 'HTTPS',
    headingBox2: 'DISPOSITIVOS',
    item1Box2: 'LEITOR/ESCRITOR DE CARTÃO',
    item2Box2: 'LEITOR DE CÓDIGO QR',
    item3Box2: 'I/O DIGITAL E ANALÓGICO',
    item4Box2: 'CONTROLADOR',
    headingBox3: 'CONTROLADOR',
    item1Box3: 'SERVIDOR EMBUTIDO',
    item2Box3: 'PLATAFORMA WEB',
    item3Box3: 'GERADOR DE REGRAS',
    item4Box3: 'SENSORES',
    headingBox4: 'INTEGRADORES',
    item1Box4: 'API COMM',
    item2Box4: 'GATEWAY DE HARDWARE',
    item3Box4: 'CONTROLE DE IOT',
    detailsHeading1: 'Ponte do passado para o futuro!',
    detailsDescription1:
      'Não queremos ouvir que você está preso a alguma tecnologia antiga e não consegue fazer uma nova implementação sem descartar todos os dispositivos antigos. Oferecemos integração com várias soluções para dar a você a oportunidade de fazer a transição gradualmente para a nova geração de OCTOSYSTEMS.',
    detailsHeading2: 'Simplicidade é a chave',
    detailsDescription2:
      'Desenvolvemos um pacote de soluções visando reduzir o tempo de instalação física, aliado a um baixo tempo de configuração e facilidade de operação. Juntos, eles podem economizar dinheiro para vendedores e compradores. O resultado final é o mais desejado em qualquer projeto: ECONOMIA DE DINHEIRO.',
    featuresHeading: 'Recursos técnicos',
    featuresTitle1: 'INTEGRAÇÃO FÁCIL COM API',
    featuresTitle2: 'CONFIGURAÇÃO INTUITIVA',
    featuresTitle3: 'CAPACIDADE DE SCRIPTS',
    featuresTitle4: 'SOHO E CORPORATIVO',
    featuresTitle5: 'MOBILE E RESPONSIVO',
    featuresTitle6: 'CONEXÃO COM FIO MÍNIMA',
    featuresTitle7: 'SAAS E ON-PREMISE',
    featuresTitle8: 'VARIOS IDIOMAS',
    featuresTitle9: 'TECNOLOGIA IOT',
    featuresTitle10: 'ACEITA LEGADO',
    featuresTitle11: 'SOLUÇÃO ACESSÍVEL',
    featuresTitle12: 'ATUALIZAÇÃO AUTOMÁTICA',

    // ---------------Models and components Section -----------

    modelsHeading: 'Modelos e Acessórios',
    modelsSubHeading: 'Um playground de dispositivos inteligentes para sua empresa',

    modelBox1: 'OCTO CONTROL',
    MnC_item1Box1: 'Access control and IOT management platform',
    MnC_item2Box1: 'SaaS Web or On Premise Appliance',
    MnC_item3Box1: 'Multi tenant',
    MnC_item4Box1: '50.000 users and 5.000 devices',
    MnC_item5Box1: 'Multi language',
    MnC_item6Box1: 'Intuitive',

    modelBox2: 'OCTO GATEWAY',
    MnC_item1Box2: 'Gateway module for until 50 devices',
    MnC_item2Box2: 'Mini UPS inside',
    MnC_item3Box2: 'Several protocols',
    MnC_item4Box2: 'Secure OCTO COMM mesh control',
    MnC_item5Box2: 'Management of health status devices',
    MnC_item6Box2: 'Fast response',
    MnC_item7Box2: 'Mini module size for convenient installation',
    MnC_item8Box2: 'Ethernet and WiFi simultaneous',

    modelBox3: 'OCTO APPLIANCE',
    MnC_item1Box3: 'Stand alone access control and IOT system',
    MnC_item2Box3: 'Sync to SaaS and works redundant',
    MnC_item3Box3: 'Web access',
    MnC_item4Box3: 'Integrates with other 3rd part systems',

    modelBox4: 'OCTO COMM READER',
    MnC_item1Box4: 'QR Code',
    MnC_item2Box4: 'MIFARE',
    MnC_item3Box4: '13.56 MHZ / 125KHZ / 433 MHZ',
    MnC_item4Box4: 'UHF',
    MnC_item5Box4: 'Wirelles data transmition',
    MnC_item6Box4: 'Octo COMM secure protocol',

    modelBox5: 'OCTO COMM I/O',
    MnC_item1Box5: 'Up to 8 Relays',
    MnC_item2Box5: 'Up to 8 Inputs',
    MnC_item3Box5: 'Able for Button uses',
    MnC_item4Box5: 'Able for Alarm reports & aquicsitions',
    MnC_item5Box5: 'Wirelles data transmition',
    MnC_item6Box5: 'Octo COMM secure protocol',

    modelBox6: 'OCTO COMM SENSOR',
    MnC_item1Box6: 'Temperature',
    MnC_item2Box6: 'Humidity',
    MnC_item3Box6: 'Energy meter',
    MnC_item4Box6: 'Moviment detection',
    MnC_item5Box6: 'Sound detection',
    MnC_item6Box6: 'Wirelles data transmition',
    MnC_item7Box6: 'Octo COMM secure protocol',
  },
  Partnership: {
    alerterHeading:'Vamos conversar ?',
    alerterContent:'Visite nossa página de contatos ….',
    alerterContact:'Fale conosco',
    hh1: 'SUA EMPRESA É',
    hh2: 'Bem-vinda',
    hh3: 'como PARCEIRA',
    heading: 'Venha se unir',
    subheading: 'Sua empresa pode escolher algumas maneiras de estar conosco!',
    card1_heading: 'Seu próprio pacote de soluções',
    card1_content:
      'Podemos fornecer a solução completa de hardware e software com sua própria marca.',
    card2_heading: 'Dispositivos de controle',
    card2_content:
      'Estamos abertos para conversar com seus dispositivos, não importa sua marca, gostaríamos de estar ao seu lado.',
    card3_heading: 'Desenvolvedores',
    card3_content:
      'A OCTOSYSTEMS tem o orgulho de ter um bom relacionamento com um grupo de desenvolvedores. Se você tiver um projeto para melhorar a solução, seja bem-vindo.',
    card4_heading: 'Seu software',
    card4_content:
      'Você está convidado a ter sua solução de software interagindo com nosso OCTOSYSTEMS.',
    card5_heading: 'Revendedores',
    card5_content:
      'Integradores podem representar a OCTOSYSTEMS em nossa área e ser mais competitivos em tecnologia e preços.',
    card6_heading: 'Serviços',
    card6_content:
      'Sua empresa pode ter nossa equipe como sua. Vamos conversar sobre isso.',
    detail1_heading: 'Fornecemos treinamento',
    detail1_content:
      'Conhecemos a importância desse assunto e gostaríamos de investir no seu conhecimento, garantindo que todos cresçamos juntos.',
    detail2_heading: 'Suporte técnico',
    detail2_content:
      'Nossos especialistas estão disponíveis em vários canais para fornecer as melhores condições para seus projetos.',
    detail3_heading: 'Falamos o seu idioma',
    detail3_content:
      'Podemos oferecer treinamento, suporte técnico e manuais em Português, Espanhol e Inglês.',
  },
  SOHO:{
    header_heading:'OCTO SOHO',
    header_content_1:'Um pequeno módulo, mas um',
    header_content_2:'poderoso e grande controlador de acessos inteligente...',
    portfolio_small_heading:'AGREGUE VALOR AO SEU PORTFÓLIO.',
    portfolio_heading:'Octosystems SOHO',
    portfolio_content:'OctoSOHO é projetado para sua melhor oferta de soluções como White Label. Simples como um kit envolvendo um gerenciamento em nuvem onde você pode ter como SaaS para seus clientes',
    portfolio_card1:'WIFI E 4G ATIVADOS',
    portfolio_card2:'MULTI IDIOMAS',
    portfolio_card3:'MULTIUSUÁRIOS',
    portfolio_card4:'API PARA INTEGRAÇÕES',
    portfolio_card5:'NUVEM E MÓVEL',
    portfolio_card6:'ATUALIZAÇÃO AUTOMÁTICA',
    portfolio_card7:'CONEXÕES SEGURAS',
    portfolio_card8:'BATERIA E RELÓGIO INTERNOS',
    access_control_h1:'Controle de Acessos Conforme suas demandas',
    access_control_h2:'',
    access_control_card1_heading:'Controles',
    access_control_card1_content:'O Octo SOHO pode controlar qualquer tipo de dispositivo por meio de relés remotos ou sem fio. APIs podem fornecer muitas outras maneiras de integrar seus eletrodomésticos, fechaduras inteligentes, motores, portas, portões. O SOHO é a solução de controle de acesso única pronta para uso com 4G. Você pode configurá-lo em poucos segundos apenas escaneando um código QR e sincronizando com sua conta. Uma ótima escolha para vários pontos em sua casa, escritórios e outros locais.',
    access_control_card2_heading:'Conexões',
    access_control_card2_content:'Além de poder usar o OCTO SOHO via WiFi e/ou 4G, também é possível ter conexões físicas. Algumas versões especiais podem fornecer WIEGAND, relé de CONTATO e ETHERNET conforme necessário. Você pode solicitar o Pacote OEM/WHITE LABEL com o melhor ajuste para seus projetos e consumidores. Se você possui dispositivos específicos que deseja gerenciar com nossa solução, é possível fazer a comunicação com eles por meio de vários modos de comunicação. ',
    access_control_card3_heading:'Gerenciamento',
    access_control_card3_content:'O OCTO CONTROL WEB gerencia todos os usuários, grupos, locais e dispositivos para você. Você pode receber facilmente notificações em seu telefone celular por meio da plataforma para qualquer alteração no status. A forma simples de operar a plataforma oferece uma maneira poderosa de gerenciar o acesso como um grande sistema corporativo, mantendo os benefícios de custo.',
    access_controller_heading:'CONTROLADOR DE ACESSOS',
    access_controller_content:`O OCTO SOHO é um dispositivo de alto desempenho e robusto para implantação rápida e fácil para usuários e aplicações de todos os níveis. Você pode simplesmente escanear um código QR usando seu telefone celular e registrar automaticamente o dispositivo na plataforma OCTOCONTROL. O OCTO SOHO já sai de fábrica com comunicação 4G habilitada *** e permite que você o instale em locais onde não há rede WiFi e oferece aplicações ilimitadas. O dispositivo possui opções para várias conexões e pode receber sinais de cartões RFID, códigos QR, Bluetooth de telefone celular ou NFC e pode controlar qualquer outro dispositivo por meio de relés, WiFi, API e qualquer outro protocolo. Não importa onde você esteja no mundo, a OCTO está disponível para você. Suas casas, escritórios, garagens, lojas, portões, armazéns e até mesmo fazendas podem ser suportados pela OCTO devido à comunicação 4G, como nenhum outro concorrente tem. Você pode escolher a cor PRETA, CINZA CLARO ou BRANCA para combinar com o local onde será instalado.`,
    control_heading:'OCTO CONTROL WEB',
    control_content:`Todos os detalhes de controle de acesso, configurações e logs podem sergerenciados por nossa plataforma SaaS na web. Você pode acessar pelo seu telefone celular, notebook, desktop ou até mesmo tablets, a plataforma é responsiva e fácil de usar. Esqueça qualquer dificuldade para operar um sistema de controle de acesso, com as soluções OCTO, você pode usar um sistema de segurança de controle de acesso de alto nível como as grandes corporações, com um investimento acessível. A plataforma pode gerenciar todos os seus locais, usuários e dispositivos como grupos para o seu conforto. Você pode ter um controle completo de todos os dispositivos e seus status em apenas um lugar.`,
    featured_table_r1c1:'Modelos OCTO',
    featured_table_r1c2:'SOHO STD',
    featured_table_r1c3:'SOHO PLUS',
    featured_table_r1c4:'SOHO ADV',
    featured_table_r2c1:'BLUETOOTH',
    featured_table_r3c1:'MIFAIR / NFC',
    featured_table_r4c1:'CÓDIGO QR',
    featured_table_r5c1:'1 RELÉ REMOTO',
    pricing_table_r1c1:'GERENCIAMENTO OCTO CONTROL WEB',
    pricing_table_r1c2:'12 Meses Grátis **',
    pricing_table_r2c1:'Usuários',
    pricing_table_r3c1:'Dispositivos Octo SOHO',
    pricing_table_r4c1:'Grupos',
    pricing_table_upto:'até 5',
    pricing_table_r5c1:'Logs',
    pricing_table_max:'máx. 500',
    pricing_table_description:'** Recursos adicionais e funcionalidades mediante assinatura.',
    approval_heading:'Aprovado por usuários domésticos e corporativos!',
    approval_content:'As vantagens de operação no modo fácil tornam essa solução a opção adequada para todos os tipos de instalações, casas, restaurantes, escritórios, armazéns, lugares compartilhados...',
    customize_solution_heading:'Para integradores e empresas de segurança:',
    customize_solution_content:'Solução de controle de acessos personalizada para pequenos escritórios e residências para colocá-lo no topo dos concorrentes. Em minutos, você pode implantar um sistema com recursos que apenas os maiores players podem oferecer a um preço acessível.',
    customize_solution_logo_alt:'Solicite sua Solução de Marca Própria',
    setup_top:'Configuração intuitiva e rápida!',
    setup_wifi1:'WI',
    setup_wifi2:'FI',
    setup_cloud:'NUVEM',
    setup_4g1:'4',
    setup_4g2:'G',
    detailed_portfolio_small_heading:'AGREGUE VALOR AO SEU PORTFÓLIO.',
    detailed_portfolio_heading:'Octosystems SOHO',
    detailed_portfolio_content:'O OctoSOHO é projetado para oferecer a melhor solução como Marca Própria. Simples como um kit que envolve um gerenciamento em nuvem, onde você pode ter como um SaaS para seus clientes.',
    detailed_portfolio_card1_heading:'Atualização automática',
    detailed_portfolio_card1_content:'Novos firmwares são atualizados automaticamente por meio da plataforma em nuvem',
    detailed_portfolio_card2_heading:'A plataforma é responsiva',
    detailed_portfolio_card2_content:'Não importa se você usa seu PC ou telefone celular, a plataforma pode suportar ambos.',
    detailed_portfolio_card3_heading:'Multilíngue',
    detailed_portfolio_card3_content:'Sua empresa pode oferecer a solução em todo o mundo',
    detailed_portfolio_card4_heading:'Multilocatário',
    detailed_portfolio_card4_content:'Você pode gerenciar vários clientes e eles podem gerenciar vários usuários',
    detailed_portfolio_card5_heading:'Bateria Interna',
    detailed_portfolio_card5_content:'Não se preocupe com cortes de energia, o sistema possui bateria interna',
    detailed_portfolio_card6_heading:'Modo baseado em serviço',
    detailed_portfolio_card6_content:'Ofereça a solução completa como um SaaS',
    detailed_portfolio_line1:'Infraestrutura mínima;',
    detailed_portfolio_line2:`Configuração fácil e rápida, P'N'P;`,
    detailed_portfolio_line3:'Basta conectar à energia e escanear o código QR;',
    detailed_portfolio_line4:'Vários tipos de aplicativos, vários tipos de dispositivos controlados;',
    detailed_portfolio_line5:'ÚNICO no mercado com 4G pré-habilitado de fábrica;',
    about_small_heading:'AUMENTE SUA EFICIÊNCIA',
    about_heading:'A Melhor Mundial trabalhando para sua empresa!',
    about_line1:'Nossa equipe possui mais de 30 anos de experiência em tecnologia.',
    about_line2:'Nosso DNA é criar soluções que se encaixem nos portfólios dos parceiros e de seus clientes. Segurança e IoT trabalham juntas em nossos sistemas para oferecer a melhor solução.',
    about_line3:'Você está convidado a conhecer melhor essa solução de controle de acessos de nova geração.',
    about_line4:'Entre em contato conosco e seja nosso parceiro',
  },  
  Contact: {
    heading: "Tem um projeto? Vamos conversar",
    line1: "Não importa o tamanho do seu projeto, estamos abertos para receber sua mensagem.",
    line2: "Por favor, nos conte um pouco sobre seu projeto e teremos prazer em trocar experiências para uma cooperação.",
    form_fname: "Primeiro nome*",
    form_lname: "Sobrenome*",
    form_title: "Cargo*",
    form_company: "Empresa*",
    form_email: "Email profissional*",
    form_submit: "Enviar",
    form_error:'Este campo é obrigatório.',
    form_footer_t1: "Ao clicar em Enviar, você concorda com a ",
    form_footer_t2: "política de privacidade da AI Lab.",
    address_detail_h1: "Damos as boas-vindas para conversar pessoalmente em nossos endereços",
    address_detail_p1: "A Octo Systems foi projetada para ser flexível e capaz de se ajustar e se adequar a todos os mercados, podendo ser personalizada para sua empresa como cliente final ou como provedor de serviços de segurança. Venha conversar sobre seus projetos.",
    address_detail_EUH: "ESCRITÓRIO NA EUROPA",
    address_detail_EUP: "Arcos de Valdevez_Portugal",
    address_detail_BRH: "ESCRITÓRIO NO BRASIL",
    address_detail_BRP: "São Paulo_Brasil",
    address_detail_email_t: "Endereço de Email",
    address_detail_email: "adm@octosystems.com.br"
    },
    Error_Page: {
      hh1: "A página que você solicitou não foi encontrada",
      hh2: "Erro: 404",
      hh3: "Por favor, verifique a URL",
    }
};

export default translation;
