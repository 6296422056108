import React, { useEffect } from 'react';
import config from '../../config.json';
import Footer from '../../components/Footer/Footer';
import classes from './Error.module.css';
import Header from './Header/Header';
import { useTranslation } from 'react-i18next';

function Error() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${t('Header:error')} - ${config.title}`;
  }, [t]);

  return (
    <div className={classes.partnershipContainer}>
      <Header />
      <Footer />
    </div>
  );
}

export default Error;
