export const icons = {
  cardIconOne: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="50px"
      viewBox="0 0 512 512"
      width="50px"
    >
      <g>
        <path d="m504.855 210.343-97.855-60.143v-135.2c0-8.284-6.716-15-15-15h-272c-8.284 0-15 6.716-15 15v135.2l-97.854 60.143c-4.434 2.725-7.138 7.554-7.146 12.758s2.683 10.04 7.108 12.778l97.892 60.559v200.562c0 8.284 6.716 15 15 15h272c8.284 0 15-6.716 15-15v-200.562l97.892-60.559c4.426-2.738 7.116-7.574 7.108-12.778s-2.712-10.034-7.145-12.758zm-369.855-180.343h242v101.762l-113.145-69.541c-4.818-2.961-10.891-2.961-15.709 0l-113.146 69.541zm242 136.975v112.744l-121 74.854-121-74.854v-112.744l121-74.368zm-333.421 56.189 61.421-37.75v75.746zm333.421 258.836h-242v-167.004l113.108 69.972c2.418 1.495 5.155 2.243 7.892 2.243 2.736 0 5.474-.748 7.892-2.243l113.108-69.972zm30-220.84v-75.746l61.421 37.75z"></path>
        <path d="m226 437c0 8.284 6.716 15 15 15h30c8.284 0 15-6.716 15-15s-6.716-15-15-15h-30c-8.284 0-15 6.716-15 15z"></path>
        <path d="m248.558 135.416-65.333 37.333c-4.673 2.671-7.558 7.641-7.558 13.023v74.666c0 5.383 2.885 10.353 7.558 13.023l65.333 37.333c2.306 1.317 4.874 1.977 7.442 1.977s5.137-.659 7.442-1.977l65.333-37.333c4.673-2.671 7.558-7.641 7.558-13.023v-74.666c0-5.383-2.885-10.353-7.558-13.023l-65.333-37.333c-4.611-2.635-10.273-2.635-14.884 0zm-42.891 76.204 35.333 20.191v40.113l-35.333-20.19zm65.333 60.304v-40.113l35.333-20.19v40.113zm20.1-86.151-35.1 20.056-35.1-20.057 35.1-20.057z"></path>
      </g>
    </svg>
  ),
  cardIconTwo: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="50px"
      viewBox="0 0 512 512"
      width="50px"
    >
      <g>
        <path d="m15 90c8.284 0 15-6.716 15-15v-45h45c8.284 0 15-6.716 15-15s-6.716-15-15-15h-60c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15z"></path>
        <path d="m497 0h-60c-8.284 0-15 6.716-15 15s6.716 15 15 15h45v45c0 8.284 6.716 15 15 15s15-6.716 15-15v-60c0-8.284-6.716-15-15-15z"></path>
        <path d="m497 422c-8.284 0-15 6.716-15 15v45h-45c-8.284 0-15 6.716-15 15s6.716 15 15 15h60c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15z"></path>
        <path d="m75 482h-45v-45c0-8.284-6.716-15-15-15s-15 6.716-15 15v60c0 8.284 6.716 15 15 15h60c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
        <path d="m487.421 265.998c5.09-5.693 5.09-14.302 0-19.995-1.181-1.321-29.406-32.714-71.597-64.485-51.827-39.028-100.46-60.771-144.824-64.814v-101.704c0-8.284-6.716-15-15-15s-15 6.716-15 15v101.704c-44.364 4.042-92.997 25.786-144.824 64.813-42.191 31.771-70.415 63.164-71.597 64.485-5.09 5.693-5.09 14.302 0 19.995 1.181 1.321 29.406 32.714 71.597 64.485 51.827 39.027 100.46 60.771 144.824 64.814v101.704c0 8.284 6.716 15 15 15s15-6.716 15-15v-101.704c44.364-4.043 92.997-25.786 144.824-64.814 42.191-31.771 70.416-63.164 71.597-64.484zm-31.928-9.991c-13.596 13.728-40.622 39.316-74.179 62.361 9.391-18.791 14.686-39.971 14.686-62.368 0-22.363-5.279-43.514-14.645-62.285 33.525 23.032 60.543 48.584 74.138 62.292zm-184.493-47.705c20.264 6.387 35 25.352 35 47.698s-14.736 41.311-35 47.698zm-30 95.396c-20.264-6.387-35-25.353-35-47.698s14.736-41.311 35-47.698zm95-47.698c0-38.984-28.036-71.526-65-78.569v-30.403c53.583 7.339 95 53.406 95 108.973s-41.417 101.634-95 108.973v-30.403c36.964-7.045 65-39.587 65-78.571zm-95-108.973v30.403c-36.964 7.043-65 39.585-65 78.569s28.036 71.526 65 78.569v30.403c-53.583-7.339-95-53.406-95-108.973s41.417-101.632 95-108.971zm-184.493 108.966c13.596-13.728 40.623-39.316 74.179-62.36-9.391 18.791-14.686 39.971-14.686 62.367 0 22.363 5.28 43.514 14.644 62.285-33.524-23.031-60.542-48.584-74.137-62.292z"></path>
      </g>
    </svg>
  ),
  locationIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="white"
      height="50px"
      width="40px"
      className="w-4 h-4"
    >
      <path
        fillRule="evenodd"
        d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
        clipRule="evenodd"
      />
    </svg>
  ),
  envelopeIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="white"
      className="w-6 h-6"
      height="50px"
      width="40px"
    >
      <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
      <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
    </svg>
  ),
  checkIcon:(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} width={18} height={18} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>

  ),
  cloudIcon:(<svg xmlns="http://www.w3.org/2000/svg" width={62} height={50} viewBox="0 0 24 24" fill="white" className="w-6 h-6">
  <path fillRule="evenodd" d="M4.5 9.75a6 6 0 0111.573-2.226 3.75 3.75 0 014.133 4.303A4.5 4.5 0 0118 20.25H6.75a5.25 5.25 0 01-2.23-10.004 6.072 6.072 0 01-.02-.496z" clipRule="evenodd" />
</svg>

),
wifiIcon:(
 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={62} height={50}  fill="white" className="w-6 h-6">
  <path fillRule="evenodd" d="M1.371 8.143c5.858-5.857 15.356-5.857 21.213 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.06 0c-4.98-4.979-13.053-4.979-18.032 0a.75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182c4.1-4.1 10.749-4.1 14.85 0a.75.75 0 010 1.061l-.53.53a.75.75 0 01-1.062 0 8.25 8.25 0 00-11.667 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.204 3.182a6 6 0 018.486 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0 3.75 3.75 0 00-5.304 0 .75.75 0 01-1.06 0l-.53-.53a.75.75 0 010-1.06zm3.182 3.182a1.5 1.5 0 012.122 0 .75.75 0 010 1.061l-.53.53a.75.75 0 01-1.061 0l-.53-.53a.75.75 0 010-1.06z" clipRule="evenodd" />
</svg>

),
chartBarIcon:(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width={62} height={50} className="w-6 h-6">
  <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z" />
</svg>

)
};
