import React, { useEffect } from 'react';
import config from '../../config.json';
import classes from './Home.module.css';
import Demo from './components/Demo/Demo';
import OfferDetails from './components/OfferDetails/OfferDetails';
import CompanyDetails from './components/CompanyDetails/CompanyDetails';
import Footer from '../../components/Footer/Footer';
import SatisfactionDetails from './components/SatisfactionDetails/SatisfactionDetails';
import Header from './components/Header/Header';
import Featured1 from '../../assets/Home/Featured1.avif'
import Featured2 from '../../assets/Home/Featured2.avif'
import Featured3 from '../../assets/Home/Featured3.avif'
import { useTranslation } from 'react-i18next';
function Home() {

  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${t('Header:home')} - ${config.title}`;
  }, [t]);

  return (
    <div className={classes.homeContainer}>
      <Header/>
      <OfferDetails />
      <CompanyDetails />
      <Demo />
      <SatisfactionDetails />
      <div className={classes.navigationContainer}>
        <div className={classes.navigationCard}>
          <img
            loading='lazy'
            className={classes.navigationImage}
            src={Featured1}
            alt={'Featured'}
          />
        </div>
        <div className={classes.navigationCard}>
          <img
            loading='lazy'
            className={classes.navigationImage}
            src={Featured2}
            alt={'Featured'}
          />
        </div>
        <div className={classes.navigationCard}>
          <img
            loading='lazy'
            className={classes.navigationImage}
            src={Featured3}
            alt={'Featured'}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
