import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Header.module.css';
import headerGif from '../../../../assets/headerGif.mp4';
import RobotOverlay from '../../../../assets/Home/Header/slide-video.webp'
import RobotWall from '../../../../assets/Home/Header/side.png'
import RedLightOverlay from '../../../../assets/Home/Header/redlightoverlay.png'
const Header = (props) => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.backgroundGifWrapper}>
        <video className={classes.gif} src={headerGif} autoPlay loop muted />
      </div>
      <div className={classes.redLightOverlay}>
        <img
          loading='lazy'
          className={classes.redLightImage}
          src={RedLightOverlay}
          alt={'red light overlay'}
        />
      </div>
      <div className={classes.col1}>
        <div className={classes.roboWall}>
          <img
            loading='lazy'
            src={RobotWall}
            alt={'robo wall'}
          />
        </div>
        <div className={classes.content}>
          <h1 className={classes.headingType}>
            {t('Header:title')}
          </h1>
          <span className={classes.bodyType}>{t('Header:description')}</span>
        </div>
      </div>
      <div className={classes.col2}>
        <div className={classes.robotOverlay}>
          <img
            loading='lazy'
            className={classes.robotImage}
            src={RobotOverlay}
            alt={'Robot overlay'}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
