import React  from 'react';
import classes from './Header.module.css';
import { useTranslation } from 'react-i18next';
import bgimage from '../../../assets/Error/headerbg.gif'

function Header() {
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
    <div className={classes.overlay}></div>
    <div className={classes.backgroundImgWrapper}>
      <img loading='lazy' alt="background" className={classes.img} src={bgimage} />
    </div>
    <div className={classes.col1}>
     <div className={classes.content}>
        <span className={classes.bodyType}>{t('Error_Page:hh1')}</span>
        <h1 className={classes.headingType}>{t('Error_Page:hh2')}</h1>
        <span className={classes.bodyType}>{t('Error_Page:hh3')}</span>
      </div>
    </div>
  </div>
  );

}

export default Header;